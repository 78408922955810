import React from "react";
import "./OutrosServicos.css";
import "../services/Services.css";

const Services = () => {
  return (
    <div className="outrosservices-wrapper">
      <div className="section-title-h1">
        <h1>OUTROS SERVIÇOS E EVENTOS</h1>
      </div>
      <div className="desktop-services">


        <div className="desktop-services-item outros-services-container">
          <div className="outros-services-header diff">
            <h2>Plataforma Insigne de Pesquisa </h2>
          </div>

          <div className="desktop-services-item-card">
            <div className="outros-services-text">
              <p>
                Um espaço virtual completo onde você pode baixar livros, assistir vídeo-aulas sobre pesquisa científica, baixar modelos de trabalhos acadêmicos e muito mais, muito mais mesmo.
              </p>
              <p>
                É uma Plataforma                 {" "}
                <span className="black-highlight">
                  desenvolvida especialmente para auxiliar
                </span> nos mais variados níveis acadêmicos (graduação, mestrado ou doutorado) e os mais variados tipos de alunos (aqueles sem tempo ou com todo tempo do mundo)! É pra você!

              </p>
            </div>
            <a href="https://insigneacademica.com.br/plataforma/" target="_blank" rel="noreferrer" alt="Instagram Editora">CLIQUE E SAIBA MAIS!</a>
          </div>

        </div>

        <div className="desktop-services-item outros-services-container">
          <div className="outros-services-header">
            <h2>ORIENTAÇÃO científica</h2>
          </div>

          <div className="desktop-services-item-card">
            <div className="outros-services-text">
              <p>
                A orientação científica da Insigne Acadêmica é para você que
                acredita que não precisa da Mentoria completa, mas quer{" "}
                <span className="black-highlight">
                  alguém para esclarecer suas dúvidas
                </span>{" "}
                de maneira rápida e eficiente.
              </p>
              <p>
                Além do acesso direto ao Prof. Pedro, você ganhará gratuitamente
                o acesso à{" "}
                <span className="black-highlight">
                  Plataforma Insigne de Pesquisa.
                </span>
              </p>
            </div>

            <a href="https://wa.me/message/Z5K7JWSGFU7ZM1" target="_blank" rel="noreferrer" alt="link whatsapp">CLIQUE E SAIBA MAIS!</a>
          </div>
        </div>
        <div className="desktop-services-item outros-services-container">
          <div className="outros-services-header">
            <h2>CONGRESSO INTERNACIONAL DE DIREITO aplicado </h2>
          </div>
          <div className="desktop-services-item-card">
            <div className="outros-services-text">
              <p>
                O Congresso Internacional de Direito Aplicado é um evento
                científico virtual, com frequência{" "}
                <span className="black-highlight">semestral</span>, que
                possibilita a apresentação dos seus trabalhos para pesquisadores e professores mundiais, bem como a publicação
                deles como capítulos de livro ou em anais de evento de maneira rápida e eficiente.
              </p>
              <p>
                As obras do CIDA são publicadas exatamente no{" "}
                <span className="black-highlight">dia do evento</span> em
                formato físico e digital pela Insigne Acadêmica Editora.
              </p>
            </div>
            <a href="https://www.instagram.com/insigneacademicacbda?igsh=MXFvNG53ZHpxZmNzdA==" target="_blank" rel="noreferrer" alt="Link Congresso">CLIQUE E SAIBA MAIS!</a>
          </div>
        </div>

        <div className="desktop-services-item outros-services-container">
          <div className="outros-services-header diff">
            <h2>Editora insigne acadêmica </h2>
          </div>

          <div className="desktop-services-item-card">
            <div className="outros-services-text">
              <p>
                A Editora Insigne Acadêmica publicará o seu trabalho
                (monografia, dissertação, tese ou coletânea){" "}
                <span className="black-highlight">
                  como livro físico e digital.
                </span>
              </p>
              <p>
                Com conselho editorial nacional e internacional, ISBN, design e
                diagramação impecáveis, a sua obra será publicada de maneira
                simples e rápida (muito rápida).
              </p>
            </div>
            <a href="https://insigneacademica.com.br/editora-insigne/" target="_blank" rel="noreferrer" alt="Instagram Editora">CLIQUE E SAIBA MAIS!</a>
          </div>

        </div>

      </div>
    </div>
  );
};

export default Services;
