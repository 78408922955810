import React from "react";

import "./Services.css";

const Services = () => {
  return (
    <div className="services-wrapper">
      <div className="section-title-h1">
        <h1>PRINCIPAIS SERVIÇOS</h1>
      </div>
      <div className="desktop-services">
        <div className="desktop-services-item">
          <h3>Consultoria</h3>
          <div className="desktop-services-item-card">
            <p>
              Definição da{" "}
              <span className="black-highlight">área de pesquisa</span> e
              auxílio na escolha do{" "}
              <span className="black-highlight">tema</span>
            </p>
            <p>
              Criação de um{" "}
              <span className="black-highlight">sumário completo</span>,
              estruturação da sua pesquisa e aula para explicação ao vivo
            </p>
            <p>
              Criação de{" "}
              <span className="black-highlight">cronograma de escrita</span> de
              acordo com necessidades do pesquisador
            </p>
            <p>
              Indicação e{" "}
              <span className="black-highlight">
                envio de trabalhos científicos
              </span>{" "}
              para as referências
            </p>
          </div>
        </div>

        <div className="desktop-services-item">
          <h3>Assessoria</h3>
          <div className="desktop-services-item-card">
            <p>
              <span className="black-highlight">Envio periódico</span> do texto
              do trabalho para análise, adequação e revisão
            </p>
            <p>
              <span className="black-highlight">Revisão linguística:</span>{" "}
              gramatical, ortográfica, estilística
            </p>
            <p>
              <span className="black-highlight">Revisão formal:</span> adequação
              aos padrões da ABNT, APA etc.
            </p>
            <p>
              <span className="black-highlight">Revisão material:</span>{" "}
              fundamentos e bases da pesquisa com foco na temática
            </p>
            <p>
              <span className="black-highlight">Revisão estrutural:</span>{" "}
              auxílio na resolução dos comentários do orientador, banca ou
              revista
            </p>
          </div>
        </div>

        <div className="desktop-services-item">
          <h3>Mentoria</h3>
          <div className="desktop-services-item-card">
            <p>
              Todos os serviços da{" "}
              <span className="black-highlight">Consultoria</span> e da{" "}
              <span className="black-highlight">Assessoria</span> + tudo que
              você precisar em relação à sua pesquisa
            </p>
            <p>
              Auxílio nos procedimentos de{" "}
              <span className="black-highlight">apresentação</span> e{" "}
              <span className="black-highlight">publicação</span> do trabalho
            </p>
            <p>
              Acesso à Plataforma Insigne de Pesquisa (
              <span className="black-highlight">vídeo-aulas e materiais</span>)
            </p>
            <p>
              <span className="black-highlight">
                Contato direto com o Prof. Pedro
              </span>{" "}
              pelo Whatsapp
            </p>
            <p>
              <span className="black-highlight">
                Esclarecimentos de dúvidas
              </span>{" "}
              sobre os procedimentos de pesquisa a qualquer horário
            </p>
            <p>
              <span className="black-highlight">
                Reuniões virtuais de orientação
              </span>{" "}
              com duração ilimitada
            </p>
            <p>
              Indicação de{" "}
              <span className="black-highlight">ferramentas digitais</span> de
              apoio à escrita
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
