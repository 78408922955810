import React from "react";
import "./Testimonials.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { CustomPrevArrow, CustomNextArrow } from "../shared/Custom";
import "../services/Services.css";
import Test1 from '../../assets/imgs/testimonials/14.png';
import Test2 from '../../assets/imgs/testimonials/15.png';
import Test3 from '../../assets/imgs/testimonials/16.png';
import Test4 from '../../assets/imgs/testimonials/17.png';
import Test5 from '../../assets/imgs/testimonials/18.png';
import Test6 from '../../assets/imgs/testimonials/19.png';
import Test7 from '../../assets/imgs/testimonials/20.png';
import Test8 from '../../assets/imgs/testimonials/21.png';
import Test9 from '../../assets/imgs/testimonials/22.png';
import Test10 from '../../assets/imgs/testimonials/23.png';
import Test11 from '../../assets/imgs/testimonials/24.png';
import Test12 from '../../assets/imgs/testimonials/25.png';
import Test13 from '../../assets/imgs/testimonials/26.png';


const Testimonials = () => {
  const settings = {
    arrows: false,
    dots: false,
    pauseOnHover: false,
    infinite: true,
    speed: 8000,
    autoplaySpeed: 8000,
    cssEase: "linear",
    autoplay: true,
    slidesToShow:3 ,
    slidesToScroll: 1,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    responsive: [
      {
        breakpoint: 1150,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 780,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]

  };


  return (
       <div className="testimonials-wrapper">
        <h1>DEPOIMENTOS DOS NOSSOS ALUNOS</h1>
       <div className="testimonials-container">
           
          <Slider {...settings} >
          <div className="testimonials-item">
            <img src={Test1} alt="imagem de depoimentos"></img>
            </div>
            <div className="testimonials-item">
            <img src={Test2} alt="imagem de depoimentos"></img>
            </div>
            <div className="testimonials-item">
            <img src={Test3} alt="imagem de depoimentos"></img>
            </div>
            <div className="testimonials-item">
            <img src={Test4} alt="imagem de depoimentos"></img>
            </div>
            <div className="testimonials-item">
            <img src={Test5} alt="imagem de depoimentos"></img>
            </div>
            <div className="testimonials-item">
            <img src={Test6} alt="imagem de depoimentos"></img>
            </div>
            <div className="testimonials-item">
            <img src={Test7} alt="imagem de depoimentos"></img>
            </div>
            <div className="testimonials-item">
            <img src={Test8} alt="imagem de depoimentos"></img>
            </div>
            <div className="testimonials-item">
            <img src={Test9} alt="imagem de depoimentos"></img>
            </div>
            <div className="testimonials-item">
            <img src={Test10} alt="imagem de depoimentos"></img>
            </div>
            <div className="testimonials-item">
            <img src={Test11} alt="imagem de depoimentos"></img>
            </div>

            <div className="testimonials-item">
            <img src={Test12} alt="imagem de depoimentos"></img>
            </div>
            <div className="testimonials-item">
            <img src={Test13} alt="imagem de depoimentos"></img>
            </div>

          </Slider>
        </div>
      
    </div>
  );
};

export default Testimonials;
