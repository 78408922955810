import React, { useState } from "react";
import "./Hero.css";
import Stats from "../stats/Stats";
import Insigne from '../../assets/imgs/insigne-marca (1).webp';


const CTA = () => {
  return (
    <div className="cta-wrapper">

      <div className="cta-layer">
        <div className="cta-container">
          <div className="home-text">
          <h4>Seja Insigne</h4>
            <h1>Conheça a Insigne Acadêmica</h1>
            <p>
              Nós ensinamos o passo a passo para desenvolver um trabalho científico de sucesso em qualquer área a partir do Método Insigne - CRP (Construção, Revisão e Publicação).
            </p>
            <p>
              Neste processo, faremos revisão formal, linguística, levantamento bibliográfico, simulação de banca, slides, tudo que você precisar e imaginar! 
            </p>
            <p>
              Seja projeto, artigo, monografia, dissertação ou tese, a Insigne Acadêmica existe para ajudar a resolver sua vida no meio científico!
            </p>
            <p>
              Ao final, caso possua dimensão e conteúdo adequados, ainda podemos publicar o seu trabalho como livro físico e digital pela Insigne Acadêmica Editora, no Congresso Internacional de Direito Aplicado ou como artigo científico na Revista Insigne de Humanidades!
            </p>
            <a href="https://forms.gle/PMzGm86Xv7oTM1WE7" target="_blank" rel="noreferrer" alt="link para mentoria" className="main-btn alt">
              Garanta sua vaga agora mesmo! clique aqui!
            </a>
          </div>
          <div className="cta-img"><img src={Insigne} alt="logo"></img></div>
        </div>
      </div>
<div className="stats-wrapper">
  
        <Stats />
</div>

    </div>
  );
};

export default CTA;
