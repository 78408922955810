import React from "react";
import "./Pillars.css";

const Pillars = () => {
  return (
    <div className="pillars-wrapper">
      <div className="pillars-container">
        <div className="pillars-items">
          <div>
            <i class="uil uil-ship"></i>
            <p>
              Sua jornada será bem mais simples se você tiver um mentor
              experiente segurando a sua mão todos os dias!
            </p>
          </div>
          <div>
            <i class="uil uil-books"></i>
            <p>
              Aqui você será conhecido por suas obras e feitos, aqui você será
              Insigne!
            </p>
          </div>
          <div>
            <i class="uil uil-trophy"></i>
            <p>
              Com a nossa ajuda, aprenda como fazer o seu trabalho científico de
              modo rápido e com muita qualidade!
            </p>
          </div>
        </div>
      </div>

    </div>
  );
};

export default Pillars;
