import React from "react";
import "./Subheader.css";

const HomeSubheader = () => {
  return (

    <div class="sub-header-container">
      <div className="sub-header">
        <p>O que você procura está na </p> <h2>página de links</h2> <a href="https://insigneacademica.com.br/links" target="_blank" rel="noreferrer" className="main-btn">Clique aqui!</a>
      </div>

    </div>
  );
};

export default HomeSubheader;

