import React, { useState } from 'react';

function FAQ() {
  const [faqs, setFaqs] = useState([
    {
      question: 'Sed consectetur tempus laoreet.',
      answer: 'Sed consectetur tempus laoreet.',
    },
    {
      question: 'Sed consectetur tempus laoreet.',
      answer: 'Sed consectetur tempus laoreet.',
    },
    {
      question: 'Sed consectetur tempus laoreet.',
      answer: 'Sed consectetur tempus laoreet.',
    },
  ]);

  return (
    <div>
      <ul>
        {faqs.map((faq, index) => (
          <li key={index}>
            <FAQItem faq={faq} />
          </li>
        ))}
      </ul>
    </div>
  );
}

function FAQItem({ faq }) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="faq-item">
      <button onClick={() => setIsOpen(!isOpen)} className='faq-btn'>
        <span>{faq.question}</span> <span className='icon'>{!isOpen ? '+' : '-'}</span>
      </button>
      {isOpen && <p>{faq.answer}</p>}
    </div>
  );
}

export default FAQ;
