
import "./About.css";
import React from 'react';
import Pedro from '../../assets/imgs/Foto (1).png';

const About = () => {
  return (
    <div className="about-wrapper" >
      <div className="about-container">


        <div className="about-text">
          <h4>Seja Insigne</h4>
          <h1>Conheça o seu Mentor</h1>

          <p>
            O Prof. Pedro da Mata é alguém que entende as suas dores e sabe
            exatamente o que você precisa em relação à pesquisa científica.
          </p>

          <p>
            Alguém que não vai desistir de você mesmo que você desista de si
            mesmo. Alguém que te fará ser Insigne.
          </p>

          <p>
            Ele possui graduação em Direito, pós-graduações em Linguística
            Aplicada, em Metodologia da Pesquisa Científica e em Docência no
            Ensino Superior, e já publicou mais de 50 trabalhos na sua área de
            pesquisa.
          </p>

          <a href="http://lattes.cnpq.br/8307765062275242" target="_blank" rel="noreferrer" alt="link para cv lattes" className="main-btn alt">
            ACESSE O CURRÍCULO LATTES DO PROF. PEDRO! CLIQUE AQUI!
          </a>
        </div>
        <div className="about-img"><img src={Pedro} alt="Imagem do mentor"></img> </div>
      </div>

    </div>
  );
};

export default About;
