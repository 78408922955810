import React, { useRef } from "react";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import "./App.css";
import "./components/hero/Hero.css";
import "./components/hero/Subheader.css";

import Header from "./components/shared/Header";
import Footer from "./components/shared/Footer";
import CTA from "./components/hero/Hero";
import Pillars from "./components/pillars/Pillars";
import About from "./components/about/About";
import HomeSubheader from "./components/hero/HomeSubheader";
import Testimonials from "./components/testimonials/Testimonials";
import Services from "./components/services/Services";
import FAQ from "./components/faq/FAQ";
import LastCTA from "./components/lastCTA/LastCTA";
import Newsletter from "./components/newsletter/Newsletter";
import Events from "./components/events/Events";
import OutrosServicos from "./components/outrosServicos/OutrosServicos";
import Equipe from "./components/equipe/Equipe";
import PrecoServicos from "./components/precoServicos/PrecoServicos";
import Plataforma from "./components/plataforma/Plataforma";

const main = "/";

function App() {
  const InicioRef = useRef();
  const AboutRef = useRef();
  const ServicesRef = useRef();
  const DepoimentosRef = useRef();
  const NewsletterRef = useRef();
  const EquipeRef = useRef();

  const scrollToComponent = (refName) => {
    const refs = {
      InicioRef,
      AboutRef,
      ServicesRef,
      DepoimentosRef,
      EquipeRef,
      NewsletterRef,
    };

    const selectedRef = refs[refName];

    if (selectedRef && selectedRef.current) {
      var yOffset;
      if (refName === "InicioRef") {
        yOffset = -180;
      } else {
        yOffset = -90;
      }

      const yCoordinate =
        selectedRef.current.getBoundingClientRect().top +
        window.scrollY +
        yOffset;
      window.scrollTo({ top: yCoordinate, behavior: "smooth" });
    }
  };

  return (
    <Router>
      <div className="App">
        <div class="header-container">
          <Header scrollToComponent={scrollToComponent} />
        </div>

        <div>
          <HomeSubheader />
        </div>

        <div ref={InicioRef}>
          <CTA />
        </div>
        <Plataforma />

        <div class="page-content">
          <div class="home-container">
            <Pillars />
            <div className="main-btn-wrapper">
              <a
                href="https://forms.gle/PMzGm86Xv7oTM1WE7"
                target="_blank"
                rel="noreferrer"
                alt="link para mentoria"
                className="main-btn"
              >
                Garanta sua vaga agora mesmo! clique aqui!
              </a>
            </div>
          </div>
        </div>
        <div ref={AboutRef}>
          <About />
        </div>
      </div>
      <div ref={ServicesRef}>
        <Services />
      </div>

      <div className="main-btn-wrapper">
        <a
          href="https://forms.gle/PMzGm86Xv7oTM1WE7"
          target="_blank"
          rel="noreferrer"
          alt="link para mentoria"
          className="main-btn"
        >
          Garanta sua vaga agora mesmo! clique aqui!
        </a>
      </div>

      <OutrosServicos />

      <div ref={DepoimentosRef}>
        <Testimonials />
      </div>
      <div ref={EquipeRef}>
        <Equipe />
      </div>

      <div ref={NewsletterRef}>
        <Newsletter />
      </div>

      <div class="page-content">
        <PrecoServicos />
      </div>

      <a
        href="https://wa.me/message/Z5K7JWSGFU7ZM1"
        alt="whatsapp link"
        target="_blank"
        rel="noreferrer"
      >
        <i className="uil uil-whatsapp whats-btn"></i>
      </a>

      <Footer scrollToComponent={scrollToComponent} />
    </Router>
  );
}

export default App;
