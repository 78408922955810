import React, { useState, useEffect, useRef } from "react";
import { CSSTransition } from "react-transition-group";
import "./Header.css";
import logo from "../../assets/imgs/logo.png";

import "./Sidebar.css";

const SidebarToggle = ({ scrollToComponent }) => {
  const [isOpen, setIsOpen] = useState(false);
  const sidebarRef = useRef(null);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const closeSidebar = (event) => {
    if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", closeSidebar);
    return () => {
      document.removeEventListener("mousedown", closeSidebar);
    };
  }, []);
  return (
    <div className="header">
      <div className="logo">
        <div
          onClick={() => {
            scrollToComponent("InicioRef");
          }}
          className="logo-link"
        >
          <img src={logo} alt="logo imagem"></img>
        </div>

        <div className="desktop-menu">
          <button
            onClick={() => {
              scrollToComponent("InicioRef");
            }}
          >
            Início
          </button>
          <button
            onClick={() => {
              scrollToComponent("AboutRef");
            }}
          >
            Sobre
          </button>
          <button
            onClick={() => {
              scrollToComponent("ServicesRef");
            }}
          >
            Serviços
          </button>
          <button
            onClick={() => {
              scrollToComponent("DepoimentosRef");
            }}
          >
            Depoimentos
          </button>
          <button
            onClick={() => {
              scrollToComponent("EquipeRef");
            }}
          >
            Equipe
          </button>
          <button
            onClick={() => {
              scrollToComponent("NewsletterRef");
            }}
          >
            Newsletter
          </button>
        </div>
      </div>

      <div className="mobile-btn">
        <button className="menu-btn" onClick={toggleSidebar}>
          <i class="uil uil-bars"></i>
        </button>
        <CSSTransition
          in={isOpen}
          timeout={500}
          classNames="fade"
          unmountOnExit
        >
          <div>
            <div className="sidebar" ref={sidebarRef}>
              <button className="close-sidebar" onClick={toggleSidebar}>
                <i class="uil uil-multiply"></i>
              </button>

              <button
                onClick={() => {
                  scrollToComponent("InicioRef");
                  toggleSidebar();
                }}
              >
                Início
              </button>
              <button
                onClick={() => {
                  scrollToComponent("AboutRef");
                  toggleSidebar();
                }}
              >
                Sobre
              </button>
              <button
                onClick={() => {
                  scrollToComponent("ServicesRef");
                  toggleSidebar();
                }}
              >
                Serviços
              </button>
              <button
                onClick={() => {
                  scrollToComponent("DepoimentosRef");
                  toggleSidebar();
                }}
              >
                Depoimentos
              </button>
              <button
                onClick={() => {
                  scrollToComponent("EquipeRef");
                  toggleSidebar();
                }}
              >
                Equipe
              </button>
              <button
                onClick={() => {
                  scrollToComponent("NewsletterRef");
                  toggleSidebar();
                }}
              >
                Newsletter
              </button>
            </div>
            <div className="curtain"></div>
          </div>
        </CSSTransition>
      </div>
    </div>
  );
};

export default SidebarToggle;
