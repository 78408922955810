const API_URL_EQUIPE = "https://insigneacademica.com.br/api/equipe";

const EquipeService = {
    async listarEquipe() {
        try {
            const response = await fetch(API_URL_EQUIPE, {
                method: "GET",
                headers: {
                    "Accept": "application/json",
                },
            });
            if (!response.ok) {
                throw new Error("Erro ao listar equipe");
            }
            return await response.json();
        } catch (error) {
            console.error("Erro:", error);
            throw error;
        }
    },
};

export default EquipeService;
