import React from "react";
import "./PrecoServicos.css";

const PrecoServicos = () => {
    return (
        <div class="preco-servicos-wrapper">

            <div className="preco-servicos-container">

                <h2>Você quer ser Insigne?</h2>

                <p>Serviços a partir de</p>
                <div className="valor">
                    <span>R$</span><span>350</span>
                </div>

                <div className="itens">
                    <ol class="numbered-list">
                        <li><span class="number">1</span><span> <span>Contato direto com o Prof. Pedro </span>pelo WhatsApp</span></li>
                        
                        <li><span class="number">2</span> <span><span>Esclarecimentos</span> de dúvidas sobre os procedimentos de pesquisa a qualquer horário</span></li>
                        <li><span class="number">3</span> <span>Acesso à <span>Plataforma Insigne de Pesquisa</span></span></li>
                        <li><span class="number">4</span> <span>Acesso à <span>Comunidade Insigne de Pesquisa</span></span></li>
                        <li><span class="number">5</span> <span>Indicação de <span>ferramentas digitais</span> de apoio à escrita</span></li>
                        <li><span class="number">6</span><span> <span>Reuniões virtuais</span> com duração ilimitada para esclarecimento de dúvidas</span></li>
                    </ol>


                    <p></p>

                </div>

                <a href="https://forms.gle/PMzGm86Xv7oTM1WE7" target="_blank" rel="noreferrer" alt="link para mentoria" className="main-btn">
                    Garanta sua vaga agora mesmo! clique aqui!
                </a>

                <p className="last-info">Se você <span>precisa de ajuda nas suas pesquisas,</span> clique no botão acima!</p>

            </div>

        </div>
    );
};

export default PrecoServicos;
