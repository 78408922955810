import React, { useState, useRef } from "react";
import "./Newsletter.css";

const CustomForm = ({ status, message, onValidated }) => {
    const [email, setEmail] = useState("");
    const [celular, setCelular] = useState("");
    const emailRef = useRef(null);
    const [aviso, setAviso] = useState(false);
    const [response, setResponse] = useState('');

    const handleSubmit = () => {
        if (email && email.includes("@")) {
            onValidated({
                EMAIL: email,
            });

            setAviso(false);
        } else {

            setAviso(true);
        }
    };

    return (
        <div>
            <div class="newsletter-feedback">
                {status === "sending" && (
                    <div style={{ color: "blue" }}>Enviando...</div>
                )}
                {status === "error" && (
                    <div
                        style={{ color: "red" }}
                        dangerouslySetInnerHTML={{ __html: message }}
                    />
                )}
                {status === "success" && (
                    <div
                        style={{ color: "green" }}
                        dangerouslySetInnerHTML={{ __html: message }}
                    />
                )}
                {aviso && <p style={{ color: "red" }}>Por favor, insira um email válido</p>}
            </div>

            <div className="newsletter-submit-div">
                <input
                    ref={emailRef}
                    type="email"
                    placeholder="Digite o seu melhor e-mail"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <button className="newsletter-submit-btn" onClick={handleSubmit}>
                    Inscreva-se <i class="uil uil-telegram-alt"></i>
                </button>
            </div>

        </div>
    );
};

export default CustomForm;
