import React from "react";
import "./Plataforma.css";

const Plataforma = () => {
    return (
        <div className="plataforma-wrapper">

            <div class="plataforma-container">
                <h2>Assista ao vídeo animado que conta exatamente a sua história:</h2>
                <iframe src="https://www.youtube.com/embed/YNG5pOX06S4?si=DO1mooWX94c9rJyW" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            </div>

        </div>
    );
};

export default Plataforma;
