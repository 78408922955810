import React from "react";
import "./Footer.css";

const scrollToTop = () => {
  window.scrollTo(0, 0);
};

const Footer = ({ scrollToComponent }) => {
  const main = "fightEvents/frontend/";

  return (
    <div className="footer__container">
      <div className="footer">
        <div className="footer__child">
          <div>
            <h3>SOBRE</h3>
            <p>
              Seja projeto, artigo, monografia, dissertação ou tese, a Insigne
              Acadêmica existe para ajudar a resolver sua vida no meio
              científico!
            </p>
          </div>

          <div className="footer-icons">
            <h3>REDES</h3>
            <div className="footer-icons-content">
              <a
                href="https://m.facebook.com/p/Insigne-Acad%C3%AAmica-100083236777478/"
                alt="whatsapp link"
                target="_blank"
                rel="noreferrer"
              >
                {" "}
                <i className="uil uil-facebook-f"></i>
              </a>

              <a
                href="https://www.instagram.com/insigneacademica/"
                alt="whatsapp link"
                target="_blank"
                rel="noreferrer"
              >
                <i className="uil uil-instagram"></i>
              </a>

              <a
                href="https://wa.me/message/Z5K7JWSGFU7ZM1"
                alt="whatsapp link"
                target="_blank"
                rel="noreferrer"
              >
                <i className="uil uil-whatsapp"></i>
              </a>
            </div>
          </div>
        </div>

        <div className="footer__child footer-links">
          <h3>SUPORTE</h3>
          <a
            href="https://wa.me/message/Z5K7JWSGFU7ZM1"
            alt="whatsapp link"
            target="_blank"
            rel="noreferrer"
          >
            Contato
          </a>
          {/* 
            <Link to={`${main}contato/`} onClick={scrollToTop}>Contato</Link> */}
        </div>

        <div className="footer__child footer__menu footer-links">
          <h3>MENU</h3>
          <button
            onClick={() => {
              scrollToComponent("InicioRef");
            }}
          >
            Início
          </button>
          <button
            onClick={() => {
              scrollToComponent("AboutRef");
            }}
          >
            Sobre
          </button>
          <button
            onClick={() => {
              scrollToComponent("ServicesRef");
            }}
          >
            Serviços
          </button>
          <button
            onClick={() => {
              scrollToComponent("DepoimentosRef");
            }}
          >
            Depoimentos
          </button>
          <button
            onClick={() => {
              scrollToComponent("EquipeRef");
            }}
          >
            Equipe
          </button>
          <button
            onClick={() => {
              scrollToComponent("NewsletterRef");
            }}
          >
            Newsletter
          </button>
        </div>
      </div>

      <div className="copy__container">
        <div className="copy">
          {" "}
          <p>
            Desenvolvido por{" "}
            <a href="https://jacquesgomes.com.br/portfolio/">Jacques Gomes</a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
