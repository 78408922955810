import React from "react";
import "./Equipe.css";

const LivroAcademico = ({ integrante }) => {
  const { imagem, nome } = integrante;

  return (
    <div className="card-img-container"><img className='img-produto' src={`https://insigneacademica.com.br/api/public/${imagem}`} alt={nome}></img></div>
  );
};

export default LivroAcademico;
