import React from "react";
import Slider from "react-slick";
import './Stats.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Testimonials = () => {
    const settings = {
        arrows: false,
        dots: false,
        pauseOnHover: false,
        infinite: true,
        speed: 8000,
        autoplaySpeed: 8000,
        cssEase: "linear",
        autoplay: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1000,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]

    };


    return (
        <div className=" ">
            <div className="stats-slider-container">

                <Slider {...settings} >
                    <div className="stats-item"><span>+300 alunos</span></div>
                    <div className="stats-item"><span>+800 formatações</span></div>
                    <div className="stats-item"><span>+30000 laudas revisadas</span></div>
                    <div className="stats-item"><span>+1000 trabalhos publicados</span></div>
                    <div className="stats-item"><span>+500 revisões linguísticas</span></div>
                </Slider>
            </div>

        </div>
    );
};

export default Testimonials;
