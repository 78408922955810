import React from 'react';

const CustomPrevArrow = ({ onClick }) => (
  <div className="custom-arrow custom-prev-arrow" onClick={onClick}>
    <i className="uil uil-angle-left-b"></i>
  </div>
);

const CustomNextArrow = ({ onClick }) => (
  <div className="custom-arrow custom-next-arrow" onClick={onClick}>
    <i className="uil uil-angle-right-b"></i>
  </div>
);

export { CustomPrevArrow, CustomNextArrow };
