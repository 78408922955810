import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import "./Equipe.css";
import { CustomPrevArrow, CustomNextArrow } from "./CustomSliderArrow";
import Produto from "./EquipeCard.js";
import "./Slider.css";
import EquipeService from "./EquipeService";


function MaisVendidos() {

  const [equipe, setEquipe] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    carregarEquipe();
  }, []);

  const carregarEquipe = async () => {
    setIsLoading(true);
    try {
      const membros = await EquipeService.listarEquipe();
      setEquipe(membros);
    } catch (error) {
      console.error("Erro ao carregar equipe:", error);
    } finally {
      setIsLoading(false);
    }
  };




  const settings = {
    arrows: false,
    dots: false,
    pauseOnHover: false,
    infinite: true,
    speed: 4000,
    autoplaySpeed: 4000,
    cssEase: "linear",
    autoplay: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    responsive: [
      {
        breakpoint: 1150,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 780,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  const main = "/editora-insigne/";
  return (
    <div className="home-equipe-wrapper">
      <div className="home-slider-container">
        <h2>nossa equipe</h2>
        <div className="maisvendidos-container">
          <Slider {...settings}>
            {equipe.map((membro) => (
              <>
                <Produto key={membro.id} integrante={membro} />
              </>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
}

export default MaisVendidos;
