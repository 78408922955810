import React from "react";
import "./Newsletter.css";
import Demo from "./Demo";

const Newsletter = () => {
  return (
    <div className="newsletter-wrapper">
      <div className="newsletter">
        <div className="newsletter-header">
          <h2>Cadastre o seu e-mail para novidades</h2>
        </div>
        <p>
          Fique por dentro dos cursos, serviços, ofertas, promoções, eventos e
          muito mais que a Insigne Acadêmica tem para te oferecer!{" "}
        </p>
        <Demo />
      </div>
    </div>
  );
};

export default Newsletter;
