import React, { useState, useEffect } from "react";
import Slider from "./Slider";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Stats.css";

const StatisticsBar = () => {

  return (
    <div className="statistics-bar">
      <Slider />
    </div>
  );
};

export default StatisticsBar;